import * as React from "react";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect-1.svg";
import entrepreneurs from "../assets/img/entreprise-creation.jpg";
import pharma from "../assets/img/pharma.jpg";
import Advisor from "../assets/img/advisor.jpg";
import speaker from "../assets/img/speaker.jpg";

const PolicyAndRegulatoryReform = () => (
  <Layout>
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-policyandregulatoryreform"
            className="block block-block-content block-block-content3ef9df28-5ecf-46bd-a14f-afa38aeb1bd9"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={entrepreneurs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Policy and Regulatory Reform
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Improving the Tunisian
                      <span> business-enabling environment</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={52}
              role="article"
              about="/en/policy-and-regulatory-reform"
              className="node node--type-activities node--promoted node--view-mode-full"
            >
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <div className="inner-card">
                                <h3>The Sector</h3>
                                <p>
                                  Tunisia’s policy reform priorities are more
                                  urgent than ever, given the impact of COVID-19
                                  on the country’s 2020 GDP of -8.8% and the
                                  resulting budget deficits. JOBS is working
                                  across multiple sectors, assisting the
                                  government on company registration and
                                  investment platforms, digitalization, and
                                  trade facilitation. This approach is essential
                                  to a business environment that attracts
                                  investors and entrepreneurs. JOBS supports
                                  tactical reforms, such as digitalization,
                                  while insisting on the sustainability of
                                  policy initiatives.
                                </p>
                              </div>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay
                            >
                              <div className="inner-card">
                                <h3>JOBS’ Vision and Strategy</h3>
                                <p>
                                  JOBS’ policy-reform strategy is focused on
                                  helping to forge a productive dialogue between
                                  businesses and government that leads to a more
                                  effective policy and regulatory environment
                                  and savings in the cost and time for
                                  businesses to be compliant. JOBS also aims to
                                  assist the government in delivering services
                                  more effectively, more transparently, and
                                  reach more citizens, especially through
                                  e-government and digital tools.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="our-work">
                      <div className="brick brick--type--our-work-workforce brick--id--81 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Our Work
                          </div>
                        </h2>
                        <div className="aos-animate" data-aos="fade-up">
                          <p>
                            JOBS provides policy and regulatory assistance to
                            the private sector and the GOT in three broad
                            categories:
                          </p>
                        </div>
                        <div className="work-card-wrapper">
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={pharma} alt="" />
                            <div className="about-work">
                              <h5>Increasing access to finance for SMEs</h5>
                              <p>
                                - Promoting new and innovative sources of
                                finance such as crowdfunding.
                                <br />
                                - Helping public funds effectively disburse
                                grant incentives to investors in underserved
                                regions.
                                <br />- Helping to offer new options for
                                financing investment through the Equity
                                Investment Code.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={Advisor} alt="" />
                            <div className="about-work">
                              <h5>
                                Making investment simpler, cheaper, and faster
                              </h5>
                              <p>
                                - Reducing the time and cost to register a
                                business in Tunisia.
                                <br />
                                - Removing unnecessary authorizations, licenses,
                                or permits to setup a new business.
                                <br />- Helping SMEs compete for public-works
                                projects through the online procurement system
                                (TUNEPS).
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={speaker} alt="" />
                            <div className="about-work">
                              <h5>Facilitating trade</h5>
                              <p>
                                - Making export and import procedures simpler
                                and less time-consuming.
                                <br />- Digitizing export and import procedures
                                through GOT online tools for more efficient
                                services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--82 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2>
                          <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                            JOBS supports Tunisia’s business enabling
                            environment in the following ways.
                          </div>
                        </h2>
                        <ul className="pl-0">
                          <li>
                            <span>
                              <span>
                                Increasing access to finance for SMEs{" "}
                              </span>
                              <br />
                              <ul>
                                <li style={{ width: "100% !important" }}>
                                  Supported ATIC and the GOT to integrate
                                  investment priorities into the Equity
                                  Investment Code.
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Making investment simpler, cheaper, and faster{" "}
                              </span>
                              <br />
                              <ul>
                                <li style={{ width: "100% !important" }}>
                                  Helped the GOT build the National Investor
                                  Platform to setup new businesses online.
                                </li>
                                <li style={{ width: "100% !important" }}>
                                  Reducing barriers for investors by removing
                                  unnecessary authorizations to obtain permits.{" "}
                                </li>
                                <li style={{ width: "100% !important" }}>
                                  Piloting a private help desk to facilitate
                                  SMEs' participation in government procurement.
                                </li>
                                <li style={{ width: "100% !important" }}>
                                  Created the Tunisia Reform Impact Model for
                                  policy makers to measure potential economic
                                  impacts of reforms.{" "}
                                </li>
                              </ul>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>Facilitating trade</span>
                              <br />
                              <ul>
                                <li style={{ width: "100% !important" }}>
                                  Helped the Ministry of Trade create a new
                                  online platform simplifying import and export
                                  procedures:{" "}
                                  <a href="../index-2.htm" target="_blank">
                                    pce.tn
                                  </a>
                                  .
                                </li>
                                <li style={{ width: "100% !important" }}>
                                  Created “Exporting Agricultural Products to
                                  the USA,” on the Ministry of Trade’s website :{" "}
                                  <a
                                    href="https://www.export2usa-tn.com/"
                                    target="_blank"
                                  >
                                    export2usa-tn.com
                                  </a>
                                </li>
                                <li style={{ width: "100% !important" }}>
                                  Simplified import and export procedures by
                                  supporting Tunisia TradeNet to create a mobile
                                  app:{" "}
                                  <a href="../index-3.htm" target="_blank">
                                    tunisietrade.net
                                  </a>{" "}
                                  - including e-payment solutions; on-line
                                  consultations; and e-learning tutorials.
                                </li>
                              </ul>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);

export default PolicyAndRegulatoryReform;
