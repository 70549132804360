import * as React from "react";
import Newsletter from "../newsletter";
import Header from "../header";
import Footer from "../Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <>{children}</>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Layout;
